
import {defineComponent, reactive, ref, h, UnwrapRef, createVNode} from 'vue';
import {useRequest} from "vue-request";
import Company from "@/request/Company";
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import Account from "@/request/Account";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
    beforeCreate() {
        isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type)  === 'admin'
        companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId);
    },
    setup() {
        const path = ref(Account.path);

        const table = ref()

        const formRef = ref();

        const params = reactive({
            companyId: companyId
        })

        const onCompanyChange = (data: any) => {
            params.companyId = data.item.id
            formData.companyId = params.companyId
            table.value ? table.value.flush(1) : null
        }

        const formData: UnwrapRef<any> = reactive({
            id: null,
            userName: '',
            account: '',
            companyId: companyId,
            password: '',
        });

        const visible = ref<boolean>(false);

        const showDrawer = () => {
            formRef.value ? formRef.value.resetFields() : '';
            visible.value = true;
            formData.id = null;
            formData.userName = '';
            formData.phone = '';
            formData.password = '';
        };

        const edit = (data: any) => {
            visible.value = true;
            formData.id = data.id;
            formData.userName = data.userName;
            formData.account = data.account
            formData.password = "******"
        }

        const onClose = () => {
            formRef.value.resetFields();
            visible.value = false;
        };

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Account.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            message.info("保存成功！")
                            table.value.flush(1)
                        } else {
                            message.error(body.msg);
                        }
                    });
                })
        }

        return {
            showCompanyList: ref<boolean>(false),
            path,
            table,
            formRef,
            visible,
            onSubmit,
            showDrawer,
            onClose,
            formData,
            edit,
            params,
            onCompanyChange,
            actions: ref<Actions[]>([
                    {
                        name: '编辑',
                        event: 'edit'
                    },
                    {
                        name: '删除',
                        event: 'del'
                    }
                ]
            ),
            rules: {
                userName: [{required: true, message: '请输入用户名'}],
                account: [{required: true, message: '请输入账号'}],
            },
            columns: ref([
                {
                    dataIndex: 'companyName',
                    title: '公司名'
                },
                {
                    dataIndex: 'userName',
                    title: '负责人'
                },
                {
                    dataIndex: 'account',
                    title: '账号(税号)'
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    slots: {customRender: 'action'},
                    fixed: 'right',
                    width: 150
                }
            ]),
        }
    }
})
