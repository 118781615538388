<template>
    <div>
        <div style="display: flex">
            <el-company-list @on-click="onCompanyChange" v-if="showCompanyList"></el-company-list>
            <div style="width: calc(100vw - 370px)">
                <a-button v-if="isAdmin" type="primary" @click="showDrawer" :style="{ marginBottom: '10px'}">
                    添加
                </a-button>

                <el-table
                        ref="table"
                        @on-mounted="showCompanyList=true"
                        :path="path"
                        :columns="columns"
                        manual
                        :actions="actions"
                        :params="params"
                        @on-edit="edit"></el-table>
            </div>
        </div>

        <a-modal
            title="账号信息"
            :width="720"
            v-model:visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
        >
            <a-form :model="formData" :rules="rules" ref="formRef" layout="vertical">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="负责人" name="userName">
                            <a-input v-model:value="formData.userName" placeholder="请输入负责人"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="账号(税号)" name="account">
                            <a-input v-model:value="formData.account" placeholder="请输入税号(用于登录)"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="密码" name="password">
                            <a-input type="password" v-model:value="formData.password" placeholder="请输入密码"/>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div
                :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
            >
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="onSubmit">保存</a-button>
            </div>
        </a-modal>
    </div>

</template>

<script lang="ts">
import {defineComponent, reactive, ref, h, UnwrapRef, createVNode} from 'vue';
import {useRequest} from "vue-request";
import Company from "@/request/Company";
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import Account from "@/request/Account";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
    beforeCreate() {
        isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type)  === 'admin'
        companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId);
    },
    setup() {
        const path = ref(Account.path);

        const table = ref()

        const formRef = ref();

        const params = reactive({
            companyId: companyId
        })

        const onCompanyChange = (data: any) => {
            params.companyId = data.item.id
            formData.companyId = params.companyId
            table.value ? table.value.flush(1) : null
        }

        const formData: UnwrapRef<any> = reactive({
            id: null,
            userName: '',
            account: '',
            companyId: companyId,
            password: '',
        });

        const visible = ref<boolean>(false);

        const showDrawer = () => {
            formRef.value ? formRef.value.resetFields() : '';
            visible.value = true;
            formData.id = null;
            formData.userName = '';
            formData.phone = '';
            formData.password = '';
        };

        const edit = (data: any) => {
            visible.value = true;
            formData.id = data.id;
            formData.userName = data.userName;
            formData.account = data.account
            formData.password = "******"
        }

        const onClose = () => {
            formRef.value.resetFields();
            visible.value = false;
        };

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Account.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            message.info("保存成功！")
                            table.value.flush(1)
                        } else {
                            message.error(body.msg);
                        }
                    });
                })
        }

        return {
            showCompanyList: ref<boolean>(false),
            path,
            table,
            formRef,
            visible,
            onSubmit,
            showDrawer,
            onClose,
            formData,
            edit,
            params,
            onCompanyChange,
            actions: ref<Actions[]>([
                    {
                        name: '编辑',
                        event: 'edit'
                    },
                    {
                        name: '删除',
                        event: 'del'
                    }
                ]
            ),
            rules: {
                userName: [{required: true, message: '请输入用户名'}],
                account: [{required: true, message: '请输入账号'}],
            },
            columns: ref([
                {
                    dataIndex: 'companyName',
                    title: '公司名'
                },
                {
                    dataIndex: 'userName',
                    title: '负责人'
                },
                {
                    dataIndex: 'account',
                    title: '账号(税号)'
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    slots: {customRender: 'action'},
                    fixed: 'right',
                    width: 150
                }
            ]),
        }
    }
})
</script>


<style lang="less">
.action {
    padding: 0 5px 0 5px;
}
</style>